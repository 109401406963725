import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Input, PrimaryButton, WithBlank } from '../components';
import { Route } from '../constants';
import { useAuthState } from '../hooks';
import styles from '../styles/pages/login.module.scss';
import { useAuth } from '../utils';

type FormData = {
  id: string;
  password: string;
};

const page: React.FC = () => {
  const router = useRouter();
  const auth = useAuth();
  const [authState] = useAuthState();
  const { handleSubmit, control } = useForm();

  useEffect(() => {
    if (authState) router.push(Route.ROOT);
  }, [authState, router]);

  const onSubmit = async (data: FormData) => {
    await auth.login(`${data.id}@orderly.jp`, data.password);
  };

  return <WithBlank loading={auth.loading} error={auth.error}>
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <img src="/orderly/symbol.svg" />
        <h2>Orderlyにログイン</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="id"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ onChange, value }) => (
              <Input
                title="ID"
                type="text"
                placeholder="admin_xxxxx"
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ onChange, value }) => (
              <Input
                title="パスワード"
                type="password"
                placeholder="半角英数字"
                onChange={onChange}
                value={value}
              />
            )}
          />
          <div className={styles.button}>
            <PrimaryButton size='large' title="ログイン" onClick={handleSubmit(onSubmit)} />
          </div>
        </form>
      </div>
    </div>
  </WithBlank>;
};

export default page;
